<template>
  <div class="">
    <Dialog
      v-model="isShown"
      type="side"
      class="font-bold text-lg fixed w-full rounded-t-2xs rounded-b-none z-30 pb-12 pt-12 max-h[800px] mt-10"
    >
      <component :is="ModalVacancyForm" v-if="isShown && selectedVacancy" :job-details="selectedVacancy" />
    </Dialog>
    <Breadcrumb :items="breadCrumbs" class="mt-5 md:mt-0 text-primary-700 gap-x-2 container mx-auto" />
    <MainTitle el="h1">
      <template #before>
        <span class="mr-2">
          {{ $t('titleSegment1') }}
        </span>
      </template>
      {{ $t('titleSegment2') }}
    </MainTitle>

    <section class="container mx-auto mt-13 flex flex-col md:flex-row md:gap-x-15 items-center">
      <div class="flex flex-col px-5 md:px-0">
        <MainTitle el="header" class="text-left">
          <template #before>
            <span class="md:text-2.5xl mr-2">
              {{ $t('messageTitleSegment1') }}
            </span>
          </template>

          <span class="md:text-2.5xl">
            {{ $t('messageTitleSegment2') }}
          </span>
        </MainTitle>

        <p class="mt-5 text-sm text-secondary-700">
          {{ $t('sectionDescription') }}
        </p>

        <AppButton
          as="a"
          variant="text-only"
          href="#join-team"
          class="flex items-center mt-5 font-bold font-display text-lg"
          @click="router.push({ path: '/careers/', hash: '#join-team' })"
        >
          {{ $t('applyNow') }}
        </AppButton>
      </div>

      <img src="/art-1.png" alt="" width="600" height="340" />
    </section>

    <section class="slider-container mx-auto mt-10">
      <MainTitle el="header" class="mb-13">
        <template #before>
          <span class="mr-2">
            {{ $t('sliderTitleSegment1') }}
          </span>
        </template>
        <span class="">
          {{ $t('sliderTitleSegment2') }}
        </span>
      </MainTitle>
      <ExpandedCutoutsOffersSlider :items="items" />
    </section>

    <CareersGraph class="mt-10 container mx-auto" />
    <section class="container mx-auto mt-20 flex flex-col items-center gap-y-8">
      <MainTitle el="h1">
        <template #before>
          <span class="mr-2">
            {{ $t('open') }}
          </span>
        </template>
        <span class="">
          {{ $t('vacancies') }}
        </span>
      </MainTitle>
      <Spinner v-if="isFetching" class="w-6 h-6" />
      <p v-if="!vacanciesList.length" class="text-md text-secondary-700">{{ $t('noVacancies') }}</p>
      <Card v-for="job in vacanciesList" :key="job.id" as="div" class="p-8 h-full flex flex-col gap-9 w-182">
        <header class="flex items-center justify-between w-full border-b pb-4">
          <p class="text-primary-A900 font-bold">{{ job.title }}</p>
          <AppButton
            class="font-light underline text-primary-500 flex items-center gap-x-1"
            variant="underlined"
            @click="handleVacancyClicked(job)"
          >
            {{ $t('viewVacancyDetails') }}
            <svg-icon name="chevron-right" width="12" height="12" class="text-primary-800 fill-current ml-3 mt-2" />
          </AppButton>
        </header>
        <ul class="flex items-center gap-x-12 w-full">
          <li>
            <p class="text-sm text-secondary-700">{{ $t('vacancyCity') }}</p>
            <p class="text-sm text-primary-A900 font-medium">{{ job.location }}</p>
          </li>
          <li>
            <p class="text-sm text-secondary-700">{{ $t('vacancyType') }}</p>
            <p class="text-sm text-primary-A900 font-medium">{{ job.job_type }}</p>
          </li>
          <li>
            <p class="text-sm text-secondary-700">{{ $t('vacancyYears') }}</p>
            <p class="text-sm text-primary-A900 font-medium">{{ job.years_of_experience }}</p>
          </li>
          <li>
            <p class="text-sm text-secondary-700">{{ $t('vacancyDate') }}</p>
            <p class="text-sm text-primary-A900 font-medium">{{ job.created_at }}</p>
          </li>
        </ul>
        <div>
          <p class="text-sm text-secondary-700">{{ $t('vacancyReqs') }}</p>
          <p class="text-sm text-primary-A900 font-medium">
            {{ job.short_desc }}
          </p>
        </div>
      </Card>
    </section>

    <section id="join-team" class="container mx-auto mt-20">
      <MainTitle el="h1">
        <template #before>
          <span class="mr-2">
            {{ $t('join') }}
          </span>
        </template>
        <span class="">
          {{ $t('team') }}
        </span>
      </MainTitle>

      <div class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-x-12 px-5 md:px-0">
        <img
          src="/careers/art-5.png"
          alt=""
          width="615"
          height="670"
          class="object-cover md:h-[670px] aspect-280-300"
        />

        <form id="join-team" @submit="submit">
          <fieldset>
            <legend class="mt-4 text-primary-A900 font-bold text-2.6xl font-display">
              {{ $t('joinOurTeam') }}
            </legend>
            <p class="mt-3 text-secondary-700">
              {{ $t('formDescription') }}
            </p>
            <div class="mt-4 flex flex-col gap-y-2">
              <TextInput
                id="full-name"
                name="fullName"
                class="w-full text-base"
                :label="$t('fullName')"
                border
                rounded
                required
              />

              <TextInput
                id="email"
                name="email"
                class="w-full text-base"
                :label="$t('email')"
                border
                rounded
                required
              />

              <TextInput
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                class="w-full text-base"
                :label="$t('phoneNumber')"
                border
                rounded
                required
              />

              <TextInput
                id="job-title"
                name="jobTitle"
                class="w-full text-base"
                :label="$t('jobTitle')"
                border
                rounded
                required
              />

              <FileInput id="cv" name="cv" accept=".pdf,.doc,.docx" :label="$t('cv')" :inner-text="$t('uploadCv')" />

              <AppButton
                class="flex items-center justify-center w-full text-lg mt-12 px-3 py-3 relative z-1 [ font-bold md:w-1/2 ]"
                variant="default"
                :loading="isSubmitting"
              >
                {{ $t('applyNow') }}
              </AppButton>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
    <!-- <section class="container mx-auto mt-20">
      <CareersContacts />
    </section> -->
  </div>
</template>
<script setup lang="ts">
import ExpandedCutoutsOffersSlider from '~/components/ExpandedCutoutsOffersSlider.vue';
import { type JobVacanciesOutput } from '~/graphql/Vacancies';
import * as yup from 'yup';
import { useMutation } from 'villus';
import { SubmitCareerFormDocument } from '~/graphql/careers';

const ModalVacancyForm = defineAsyncComponent(() => import('../components/ModalVacancyForm.vue'));

const { error, success } = useAlerts();
const router = useRouter();

const showAlerts = ({ type }) => {
  isShown.value = false;
  if (type === 'success') {
    success($t('vacancySubmitted'));
  } else {
    error($t('formSubmissionError'));
  }
};
provide('showAlerts', showAlerts);
const { formatPageTitle } = useSeoFormatting();
const isShown = ref(false);
const selectedVacancy = ref<JobVacanciesOutput | null>(null);

const { t } = useI18n({
  useScope: 'global',
});
const { t: $t } = useI18n({
  useScope: 'local',
});

useAppSeoMeta({
  title: formatPageTitle($t('pageTitle') as string),
  description: $t('description') as string,
});
const { vacancies, isFetching } = useVacancies();
const vacanciesList = computed(() => {
  if (vacancies.value) {
    return vacancies.value.map(vacancy => ({
      ...vacancy,
      created_at: formatDate(new Date(vacancy!.created_at), 'en') || ' ',
      short_desc: getShortDescription(vacancy!.requirements),
      years_of_experience:
        Number(vacancy!.years_of_experience) > 1
          ? `${vacancy!.years_of_experience} ${$t('yearsOfExp')}`
          : `${vacancy!.years_of_experience} ${$t('yearOfExp')}`,
    }));
  }
  return [];
});

const breadCrumbs = [
  {
    label: $t('home'),
    path: '/',
  },
  {
    label: $t('pageTitle'),
    path: '/careers',
  },
];

const items = [
  {
    id: 1,
    image: '/careers/art-1.png',
    title: $t('coreValues'),
    description: $t('coreValuesDescription'),
  },
  {
    id: 2,
    image: '/careers/art-2.png',
    title: $t('driveForResults'),
    description: $t('driveForResultsDescriptions'),
  },
  {
    id: 3,
    image: '/careers/art-3.png',
    title: $t('actWithIntegrity'),
    description: $t('actWithIntegrityDescriptions'),
  },
  {
    id: 4,
    image: '/careers/art-4.png',
    title: $t('createSynergy'),
    description: $t('createSynergyDescriptions'),
  },
];

const { execute } = useMutation(SubmitCareerFormDocument);
const { handleSubmit, isSubmitting, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      fullName: yup.string().required(t('validation.required')),
      email: yup.string().email().required(t('validation.required')),
      phoneNumber: yup.string().required(t('validation.required')),
      jobTitle: yup.string().required(t('validation.required')),
      cv: yup
        .mixed<File>()
        .test('required', t('validation.required'), value => {
          return value && value.size > 0;
        })
        .test('fileSize', 'File too large', value => {
          return value && value.size <= 5000000;
        })
        .test('fileType', 'Unsupported File Format', value => {
          return (
            value &&
            [
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ].includes(value.type)
          );
        }),
    }),
  ),
});

const submit = handleSubmit(async value => {
  try {
    await execute({
      input: {
        name: value.fullName,
        email: value.email,
        phone_number: value.phoneNumber,
        attachment: value.cv ? await extractBase64WithoutHeader(value.cv) : '',
        title: value.jobTitle,
      },
    });
    success($t('formSubmitted'));
    resetForm();
  } catch (e) {
    error($t('formSubmissionError'));
    console.error(e);
  }
});

const handleVacancyClicked = (vacancy: JobVacanciesOutput) => {
  selectedVacancy.value = vacancy;
  isShown.value = true;
};
</script>
<i18n>
  {
    "en": {
      "pageTitle": "Our Careers",
      "description": "Join our team and help us build the future of e-commerce.",
      "home": "Home",
      "messageTitleSegment1": "Life At",
      "messageTitleSegment2": "Miraco",
      "miraco": "Miraco",
      "sectionDescription": "Embark on a fulfilling career journey with us, where we understand the challenges of finding a meaningful job. We strive to simplify this process for you, fostering a satisfying work environment that you'll eagerly anticipate each day. Begin your exploration of exciting opportunities by perusing our current job listings.",
      "titleSegment1": "Our",
      "titleSegment2": "Careers",
      "applyNow": "Apply Now",
      "join": "Join Miraco's",
      "open": "Open",
      "vacancies": "Vacancies",
      "viewVacancyDetails": "View Vacancy Details",
      "team": "Team",
      "sliderTitleSegment1": "Our",
      "sliderTitleSegment2": "Values",
      "joinOurTeam": "Interested? Join Our Team",
      "formDescription": "Passionate about Success? Join Our Team Today and Make an Impact and Fuel Your Success!",
      "fullName": "Full Name",
      "email": "Email",
      "phoneNumber": "Phone Number",
      "cv": "CV",
      "uploadCv": "Upload CV",
      "formSubmitted": "Your form has been submitted successfully",
      "formSubmissionError": "An error occurred while submitting the form",
      "submit": "Submit",
      "or": "Or",
      "contactInfo": "Contact Information",
      "address": "112 Thawra Street, Heliopolis, Cairo 2nd Floor, Apartment 4",
      "sendMessage": "Send us a Message",
      "success": {
        "title": "Message Sent!",
        "subtitle": "We will contact you shortly"
      },
      "error": {
        "title": "Failed",
        "subtitle": "Sorry something went wrong while sending the message, try again later"
      },
      "placeholders": {
        "message": "Add your message here",
        "subject": "Message subject"
      },
      "coreValues": "Core Values",
      "coreValuesDescription": "We commit to deliver profitable growth through Driving for Results, Acting with Integrity and Creating Synergy.",
      "driveForResults": "Drive for Results",
      "driveForResultsDescriptions": "Push the limits and continue to raise the bar to hit higher targets, Discuss constructively and commit to deliver the expected results, Strive for profitable growth with self-sufficiency",
      "actWithIntegrity": "Act with Integrity",
      "actWithIntegrityDescriptions": "Ensure compliance in all areas that you are involved, Speak up when identify problems, Understand the facts before making judgments, and act swiftly upon decisions",
      "createSynergy": "Create Synergy",
      "createSynergyDescriptions": "Leverage others' strengths and align with group targets, Embrace diversity to add value to the organization, Customer-oriented to find win-win solutions",
      "jobTitle": "Job Title",
      "vacancyCity": "City",
      "vacancyType": "Location Type",
      "vacancyDate": "Posted On",
      "vacancyYears": "Years of Experience",
      "vacancyReqs": "Job Requirements",
      "yearOfExp": "Year",
      "yearsOfExp": "Years",
      "vacancySubmitted": "Your application has been submitted successfully",
      "noVacancies": "There are no open vacancies at the moment"
    },
    "ar": {
      "pageTitle": "الوظائف المتاحة",
      "description": "انضم إلى فريقنا وساعدنا في بناء مستقبل التجارة الإلكترونية.",
      "home": "الرئيسية",
      "messageTitleSegment1": "الحياه فى",
      "messageTitleSegment2": "ميراكو",
      "miraco": "ميراكو",
      "sectionDescription": "ابدأ رحلتك المهنية معنا، حيث ندرك تمامًا التحديات التي تواجهها في البحث عن وظيفة ذات مغزى. نحن هنا لتسهيل هذه العملية عليك، ولخلق بيئة عمل محفزة تجعلك تتطلع إلى كل يوم جديد. استكشف الفرص المثيرة المتاحة لدينا الآن من خلال الاطلاع على قوائم الوظائف الحالية.",
      "titleSegment1": "وظائف",
      "titleSegment2": "ميراكو",
      "applyNow": "قدم الآن",
      "join": "انضم إلى",
      "team": "فريق ميراكو",
      "open": "الوظائف",
      "vacancies": "المتاحة",
      "sliderTitleSegment1": "القيم",
      "sliderTitleSegment2": "الأساسية",
      "joinOurTeam": "هل أنت مهتم؟ انضم إلى فريقنا",
      "formDescription": "هل تهتم بالنجاح؟ انضم إلى فريقنا اليوم وكن جزءًا من نجاحنا!",
      "uploadCv": "تحميل السيرة الذاتية",
      "formSubmitted": "تم تقديم النموذج بنجاح",
      "formSubmissionError": "حدث خطأ أثناء تقديم النموذج",
      "success": {
        "title": "تم الارسال بنجاح",
        "subtitle": "سيتم التواصل معك في وقت لاحق"
      },
      "error": {
        "title": "فشل",
        "subtitle": "حدث خطأ أثناء الارسال، حاول مرة أخرى"
      },
      "fullName": "الاسم الكامل",
      "email": "البريد الإلكتروني",
      "phoneNumber": "رقم الهاتف",
      "cv": "السيرة الذاتية",
      "submit": "تقديم",
      "or": "او",
      "contactInfo": "معلومات الاتصال",
      "address": "112 شارع طوقلي، مدينة الهيئة، القاهرة، الساحل الشمالي، الشقة 4",
      "sendMessage": "ارسال رسالة",
      "coreValues": "القيم الأساسيه",
      "coreValuesDescription": "نحن نلتزم بالوفاء بتقديم النمو المربح من خلال الدفع نحو النتائج -التصرف بنزاهه -وإبداع التآزر",
      "driveForResults": "إدفع نحو النتائج",
      "driveForResultsDescriptions": "تخطى الحدود وداوم على رفع المحدد لتحقيق أهداف أعلى, ناقش بشكل بناء والتزم بتقديم النتائج المرجوه.,إبذل قصارى جهدك لتحقيق نمو مربح بإكتفاء ذاتى",
      "actWithIntegrity": "تصرف بنزاهه",
      "actWithIntegrityDescriptions": "تأكد من الإمتثال للقوانين فى كل المجالات التى تشارك فيها,تكلم عند تحديد المشكلات, إفهم الحقائق قبل إصدار أحكام وتصرف سريعا عندما تتخذ القرارات ",
      "createSynergy": "إبدع التآزر",
      "createSynergyDescriptions": "تخطي الحدود وتكون بناء محترفين لتحقيق أهداف أعلى",
      "jobTitle": "المسمى الوظيفي",
      "viewVacancyDetails": "اعرض التفاصيل",
      "vacancyCity": "المدينه",
      "vacancyType": "التنقل",
      "vacancyDate": "تاريخ النشر",
      "vacancyYears": "سنوات الخبرة",
      "vacancyReqs": "متطلبات الوظيفة",
      "yearOfExp": "سنة",
      "yearsOfExp": "سنوات",
      "vacancySubmitted": "تم تقديم الطلب بنجاح",
      "noVacancies": "لا توجد وظائف متاحة حاليا"
    }
  }
  </i18n>

<style lang="postcss" scoped>
.slider-container {
  max-width: 1366px;
}
</style>
